import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';

import { Container } from 'modules/common/ui';

import { Root } from './Layout.Root';
import { Heading } from './Layout.Heading';
import { Subheading } from './Layout.Subheading';
import { Body } from './Layout.Body';
import { Footer } from './Layout.Footer';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Заголовок страницы сегмента Login.
   */
  heading?: ReactNode;

  /**
   * Подзаголовок страницы сегмента Login.
   */
  subheading?: ReactNode;

  /**
   * Подвал страницы сегмента Login
   */
  footer?: ReactNode;
};

/**
 * Обертка для страниц сегмента Login
 */
export const Layout: FC<Props> = ({
  heading,
  subheading,
  footer,
  children,
  ...props
}) => (
  <Root {...props}>
    <Container size="small">
      {heading && <Heading>{heading}</Heading>}
      {subheading && <Subheading>{subheading}</Subheading>}
      <Body>{children}</Body>
      {footer && <Footer>{footer}</Footer>}
    </Container>
  </Root>
);
