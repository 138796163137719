import styled from '@emotion/styled';

/**
 * Контент находящийся в Layout.
 */
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
