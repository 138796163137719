import { CustomError } from '@devim-front/error';

/**
 * Представляет ошибку, обозначающую попытку использования неправильного
 * ПИН-кода.
 */
export class InvalidPinError extends CustomError {
  public constructor(innerError: Error) {
    super('InvalidPinError', innerError.message);
  }
}
