import * as yup from 'yup';

import { PinHelper } from 'modules/common/values';

/**
 * Схема формы ПИН-кода.
 */
export const schema = yup.object().shape({
  /**
   * ПИН-код.
   */
  pin: yup
    .string()
    .required('Введите ПИН-код')
    .matches(PinHelper.VALIDATION_REGEXP, 'Неверный ПИН-код'),
});

/**
 * Значения схемы.
 */
export type Values = yup.InferType<typeof schema>;
