import * as yup from 'yup';

/**
 * Схема формы создания ПИН-кода.
 */
export const schema = yup.object().shape({
  /**
   * ПИН-код.
   */
  pin: yup.string().required('Введите ПИН-код'),

  /**
   * Повторно введённый ПИН-код для проверки.
   */
  repeat: yup
    .string()
    .required('Введите ПИН-код')
    .oneOf([yup.ref('pin')], 'ПИН-код не совпадает'),
});

/**
 * Значения схемы.
 */
export type Values = yup.InferType<typeof schema>;
