import { FC, ComponentPropsWithoutRef } from 'react';
import { observer } from 'mobx-react';

import { Nowrap } from 'modules/common/ui';
import { PhoneHelper } from 'modules/common/values';

import { Store } from '../../../stores';

import { Layout as Base } from '../../Layout';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Base>,
  'heading' | 'subheading' | 'footer'
>;

/**
 * Задаёт каркас и служебные тексты.
 */
const Layout: FC<Props> = ({ ...props }) => {
  const { phone } = Store.use();
  const formattedPhone = phone ? PhoneHelper.format(String(phone.value)) : '';

  return (
    <Base
      {...props}
      heading="Восстановление пин-кода"
      subheading={
        <>
          Создайте новый 4-х значный пин-код из цифр для входа в личный кабинет
          по номеру <Nowrap>{formattedPhone}</Nowrap>
        </>
      }
    />
  );
};

const component = observer(Layout);
export { component as Layout };
