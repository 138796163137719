import { FC, ComponentPropsWithoutRef } from 'react';

import { SlideTransition } from 'modules/common/transition';

import { Heading } from './Content.Heading';
import { Inner } from './Content.Inner';
import { Root } from './Content.Root';
import { Text } from './Content.Text';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof SlideTransition>;

/**
 * Отображает содержимое блока "Не приходит код".
 */
export const Content: FC<Props> = ({ ...props }) => (
  <SlideTransition {...props}>
    <Root>
      <Inner>
        <Heading>Возможная причина:</Heading>
        <Text>
          Возможно, вы ввели неверный номер телефона на предыдущем шаге.
          Проверьте номер. Если вы уверены, что номер введён правильно, а СМС не
          пришло в течении 15 минут, пожалуйста, обратитесь в нашу техподдержку.
        </Text>
      </Inner>
    </Root>
  </SlideTransition>
);
