import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Стили для подзаголовока Layout.
 */
export const Subheading = styled.h4`
  font-size: 1.18rem;
  line-height: 1.87rem;
  font-weight: normal;
  text-align: center;
  color: ${(props) => props.theme.colors.light.text.secondary};

  margin: 0;
  margin-top: 0.75rem;

  @media ${lessThanContainer('small')} {
    font-size: 0.875rem;
    line-height: 1rem;

    margin-top: 0.25rem;
  }
`;
