import { CustomError } from '@devim-front/error';

/**
 * Представляет ошибку, когда пользователь пытался получить новый код подтверждения
 * слишком много раз.
 */
export class LimitExceededError extends CustomError {
  public constructor(innerError: Error) {
    super('LimitExceededError', innerError.message);
  }
}
