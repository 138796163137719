import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент компонента.
 */
export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: ${(p) => p.theme.container.size.small}px;

  @media ${lessThanContainer('small')} {
    width: 100%;
  }
`;
