import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент Layout.
 */
export const Root = styled.div`
  margin: 5rem 0;

  @media ${lessThanContainer('small')} {
    margin: 2.5rem 0;
  }
`;
