import { LazyService } from '@devim-front/service';

import { PhoneModel } from '../types';
/**
 * Сервис, осуществляющий работу с методами аутентификации.
 */
export class Service extends LazyService {
  /**
   * Ключ, по которому сохраняется модель телефона в sessionStorage.
   */
  private PHONE_KEY = 'authPhone';

  /**
   * Ключ, по которому сохраняется пин-код в sessionStorage.
   */
  private PIN_KEY = 'authPin';

  /**
   * Возвращает сохранённую модель номера телефона или undefined, если она
   * отсутствует.
   */
  public readPhone() {
    const value = window.sessionStorage.getItem(this.PHONE_KEY);

    if (value === null) {
      return undefined;
    }

    return JSON.parse(value) as PhoneModel;
  }

  /**
   * Сохраняет модель номера телефона или удаляет её, если передано значение
   * undefined.
   * @param phone Значение модели.
   */
  public savePhone(phone?: PhoneModel) {
    if (phone == null) {
      window.sessionStorage.removeItem(this.PHONE_KEY);
    } else {
      window.sessionStorage.setItem(this.PHONE_KEY, JSON.stringify(phone));
    }
  }

  /**
   * Возвращает пин-код, введённый пользователем, из sessionStorage.
   */
  public readPin() {
    const value = window.sessionStorage.getItem(this.PIN_KEY);
    return value ? String(value) : undefined;
  }

  /**
   * Сохраняет пин-код, введённый пользователем, в sessionStorage.
   * @param pin Пин-код.
   */
  public savePin(pin?: string) {
    if (pin == null) {
      window.sessionStorage.removeItem(this.PIN_KEY);
    } else {
      window.sessionStorage.setItem(this.PIN_KEY, pin);
    }
  }
}
