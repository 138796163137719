import { FC } from 'react';
import Head from 'next/head';

import { PageContent } from 'modules/common/layout';

import { PhoneForm } from '../PhoneForm';

import { Layout } from './Layout';
import { Access } from './Access';

/**
 * Отображает страницу ввода номера телефона при авторизации.
 */
export const PhonePage: FC = () => (
  <Access>
    <PageContent height="full" offset="none">
      <Head>
        <title>Вход в Аполлон</title>
      </Head>
      <Layout>
        <PhoneForm />
      </Layout>
    </PageContent>
  </Access>
);
