import { FC, ComponentPropsWithoutRef } from 'react';

import { Root } from './Form.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Базовый класс формы для страниц логина.
 */
export const Form: FC<Props> = (props) => <Root {...props} />;
