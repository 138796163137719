/**
 * Состояние пользователя на сервере.
 */
export enum UserState {
  /**
   * Состояние неизвестно, новый клиент.
   */
  Unknown = 'UNKNOWN',

  /**
   * Клиент существует, но пароль не задан.
   */
  WithoutPassword = 'WITHOUT_PASSWORD',

  /**
   * Клиент существует и имеет заданный пароль.
   */
  Active = 'ACTIVE',
}
