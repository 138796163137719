import styled from '@emotion/styled';

import { Form } from '../Form';

/**
 * Корневой элемент формы.
 */
export const Root = styled(Form)`
  display: grid;
  grid-auto-rows: min-content;
  grid-auto-flow: row;
  gap: 1rem;
`;
