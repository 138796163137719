import { FC } from 'react';
import Head from 'next/head';

import { Container } from 'modules/common/ui';
import { PageContent } from 'modules/common/layout';

import { PinConfirmationForm } from '../PinConfirmationForm';
import { Access } from '../Access';

import { Layout } from './Layout';

/**
 * Отображает содержимое страницы подтверждения нового пин-кода через СМС.
 */
export const PinConfirmationPage: FC = () => (
  <Access phoneRequired pinRequired>
    <PageContent height="full" offset="none">
      <Head>
        <title>Введите код из СМС</title>
      </Head>
      <Container size="small" offset="large">
        <Layout>
          <PinConfirmationForm />
        </Layout>
      </Container>
    </PageContent>
  </Access>
);
