import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Стили для подвала Layout.
 */
export const Footer = styled.div`
  font-size: 0.8rem;
  line-height: 1.125rem;
  font-weight: 300;
  text-align: center;
  color: ${({ theme }) => theme.colors.light.text.secondary};

  p {
    margin: 0;
    margin-top: 1.25rem;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.light.text.secondary};
    text-decoration: underline;
  }

  @media ${lessThanContainer('small')} {
    text-align: left;
  }

  margin-top: 3.5rem;
`;
