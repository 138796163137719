import { FC, ComponentPropsWithoutRef } from 'react';

import { Layout as Base } from '../../Layout';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Base>,
  'heading' | 'subheading' | 'footer'
>;

/**
 * Задаёт каркас и служебные тексты.
 */
export const Layout: FC<Props> = ({ ...props }) => (
  <Base
    {...props}
    heading="Вход в Аполлон"
    subheading="Введите номер телефона для входа"
    footer={
      <>
        <p>
          Указывая номер телефона, вы соглашаетесь на обработку{' '}
          <a
            href="/docs/07-soglasiye-na-obrabotku-obrabotke-personalnyh-dannyh-ooo-mkk-dzp-razvitie-7.pdf"
            target="_blank"
            rel="noreferrer"
          >
            персональных данных
          </a>
          .
        </p>
        <p>
          Обращаем ваше внимание, что указанный вами номер телефона является
          верификационным, и на него будут отправляться подтверждающие СМС.
          Также он является логином в личном кабинете.
        </p>
      </>
    }
  />
);
