import { FC, ComponentPropsWithoutRef, useCallback } from 'react';
import { observer } from 'mobx-react';

import { Field, Button, CodeInput } from 'modules/common/ui';
import { Store } from 'modules/login';

import { schema } from './NewPinForm.Schema';
import { Root } from './NewPinForm.Root';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'schema'>;

/**
 * Страница создания ПИН-кода.
 */
const NewPinForm: FC<Props> = ({ ...props }) => {
  const store = Store.use();

  const createPin = useCallback(
    (data: any) => {
      store.createPin(data.pin);
    },
    [store],
  );

  const handleCancel = useCallback(() => {
    store.back();
  }, [store]);

  return (
    <Root {...props} schema={schema} onSubmit={createPin} autoComplete="off">
      <Field
        label="Новый пин-код"
        align="center"
        htmlFor="pin"
        name="pin"
        error={store.errorMessage}
      >
        <CodeInput id="pin" name="pin" secure autoFocus />
      </Field>

      <Field
        label="Повторите пин-код"
        align="center"
        htmlFor="repeat"
        name="repeat"
      >
        <CodeInput id="repeat" name="repeat" secure />
      </Field>

      <Button
        type="submit"
        variant="filled"
        width="full"
        pending={store.pending}
      >
        Войти
      </Button>
      <Button onClick={handleCancel} color="info" size="small">
        Отмена
      </Button>
    </Root>
  );
};

const component = observer(NewPinForm);
export { component as NewPinForm };
