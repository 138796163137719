import { ComponentPropsWithoutRef, useCallback, FC } from 'react';
import { useFormikContext } from 'formik';

import { CodeInput, Helper } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof CodeInput>,
  'autoComplete' | 'name'
> & {
  /**
   * Уникальное название компонента в рамках формы.
   */
  name: string;
};

/**
 * Отображает поле ввода кода подтверждения.
 */
export const Input: FC<Props> = ({ onChange, name, ...props }) => {
  const form = useFormikContext();

  const handleChange = useCallback(
    async (value: string) => {
      if (onChange) {
        onChange(value);
      }

      await Promise.resolve();

      if (value.length < 4) {
        return;
      }

      form.submitForm();
    },
    [onChange, form],
  );

  Boolean(form);

  const autoComplete = Helper.isOTPSupported() ? 'one-time-code' : 'off';

  return (
    <>
      <CodeInput
        {...props}
        autoComplete={autoComplete}
        onChange={handleChange}
        name={name}
      />
    </>
  );
};
