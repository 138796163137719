import { ComponentPropsWithoutRef, FC } from 'react';

import { Caret } from './Toggle.Caret';
import { Root } from './Toggle.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Указывает, что блок в данный момент развёрнут.
   */
  active?: boolean;
};

/**
 * Отображает ссылку, которая скрывает / разворачивает содержимое блока "Не
 * приходит код".
 */
export const Toggle: FC<Props> = ({ children, active, ...props }) => (
  <Root {...props} type="button">
    Не приходит код подтверждения?
    <Caret active={active} />
  </Root>
);
