import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Стили для заголовка Layout.
 */
export const Heading = styled.h3`
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 700;
  text-align: center;
  color: ${(props) => props.theme.colors.light.text.primary};

  margin: 0;

  @media ${lessThanContainer('small')} {
    font-weight: 600;
    font-size: 1.5rem;
  }
`;
