import styled from '@emotion/styled';

/**
 * Корневой элемент компонента Toggle.
 */
export const Root = styled.button`
  position: relative;
  display: inline-flex;

  margin-right: auto;
  margin-left: auto;

  padding: 0;
  border: 0;
  outline: 0;

  color: ${({ theme }) => theme.color.info.main};

  background-color: transparent;

  font-size: 1rem;

  cursor: pointer;
`;
