import { FC } from 'react';
import Head from 'next/head';

import { Container } from 'modules/common/ui';
import { PageContent } from 'modules/common/layout';
import { UserState } from 'services/RpcService/types/UserState';

import { PinForm } from '../PinForm';
import { Access } from '../Access';

import { Layout } from './Layout';

/**
 * Отображает содержимое страницы ввода существующего пин-кода.
 */
export const PinPage: FC = () => (
  <Access phoneRequired userState={UserState.Active}>
    <PageContent height="full" offset="none">
      <Head>
        <title>Введите ваш пин-код</title>
      </Head>
      <Container size="small" offset="large">
        <Layout>
          <PinForm />
        </Layout>
      </Container>
    </PageContent>
  </Access>
);
