import { FC, PropsWithChildren } from 'react';
import { observer } from 'mobx-react';

import { Page, Redirect } from 'modules/common/routing';
import { PageLoading } from 'modules/common/layout';

import { Store } from '../../stores';
import { UserState } from 'services/RpcService/types/UserState';

/**
 * Свойства компонента.
 */
type Props = PropsWithChildren<{
  /**
   * Указывает на то, что для входа на данную страницу требуется, чтобы
   * пользователь уже ввёл номер телефона.
   */
  phoneRequired?: boolean;

  /**
   * Указывает на то, что для входа на данную страницу требуется, чтобы
   * пользователь уже ввёл телефон и чтобы тип данного телефона соответствовал
   * указанному.
   */
  userState?: UserState;

  /**
   * Указывает на то, что для входа на данную страницу требуется, чтобы
   * пользователь уже ввёл новый пин-код.
   */
  pinRequired?: boolean;
}>;

/**
 * Проверяет, соответствует ли текущее состояние процесса авторизации условиям,
 * заданным в свойствах компонента, и, если нет, перенаправляет пользователя
 * на главную страницу раздела авторизации.
 */
const Access: FC<Props> = ({
  phoneRequired,
  pinRequired,
  userState,
  children,
}) => {
  const store = Store.use();

  if (!store.ready) {
    return <PageLoading />;
  }

  if (userState && (store.phone == null || store.phone.type !== userState)) {
    return (
      <Redirect url={Page.LOGIN} replace>
        <PageLoading />
      </Redirect>
    );
  }

  if (phoneRequired && store.phone == null) {
    return (
      <Redirect url={Page.LOGIN} replace>
        <PageLoading />
      </Redirect>
    );
  }

  if (pinRequired && store.pin == null) {
    return (
      <Redirect url={Page.LOGIN} replace>
        <PageLoading />
      </Redirect>
    );
  }

  return <>{children}</>;
};

const component = observer(Access);
export { component as Access };
