import { ComponentPropsWithoutRef, useCallback, FC } from 'react';
import { useFormikContext } from 'formik';

import { CodeInput } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof CodeInput>;

/**
 * Отображает поле ввода кода подтверждения.
 */
export const Input: FC<Props> = ({ onChange, ...props }) => {
  const form = useFormikContext();

  const handleChange = useCallback(
    async (value: string) => {
      if (onChange) {
        onChange(value);
      }

      await Promise.resolve();

      if (value.length < 4) {
        return;
      }

      form.submitForm();
    },
    [onChange, form],
  );

  return <CodeInput {...props} onChange={handleChange} />;
};
