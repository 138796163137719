import { LazyStore, reactive } from '@devim-front/store';
import { observable, computed, action, runInAction } from 'mobx';

import { Store as AuthStore, TokensModel } from 'modules/common/auth';
import { Store as RoutingStore, Page } from 'modules/common/routing';
import { applyFetchable } from 'modules/common/stores';

import { PhoneModel } from '../types';
import { Service } from '../services';
import {
  InvalidPinError,
  InvalidCodeError,
  LimitExceededError,
} from '../errors';
import { CompatService } from 'services/CompatService';
import { UserState } from 'services/RpcService/types/UserState';
import { RpcError } from 'services/RpcService/errors/RpcError';
import { RpcErrorCode } from 'services/RpcService/types/RpcErrorCode';
import { AuthTokens } from 'services/RpcService/types/AuthTokens';

/**
 * Хранилище обеспечиваеющее процесс входа.
 */
@reactive
export class Store extends applyFetchable(LazyStore) {
  /**
   * Телефон, который ввёл пользователь на странице ввода телефона.
   */
  @observable
  public phone?: PhoneModel;

  /**
   * Новый пин-код, который ввёл пользователь на странице создания или смены пин-кода.
   */
  @observable
  public pin?: string;

  /*
   * Ошибка, которую вернул сервер после последней операции.
   */
  @observable
  public error?: InvalidPinError | InvalidCodeError | LimitExceededError;

  /**
   * Указывает, что в данный момент происходит асинхронная операция.
   */
  @observable
  public pending: boolean = false;

  /**
   * Текущее количество секунд, через которое можно будет отправить код подтверждения повторно.
   */
  @observable
  public countdown: number = 0;

  /**
   * Идентификатор setInterval при отправке кода подтверждения.
   */
  private interval?: any;

  /**
   * Экземпляр сервиса Routing.
   */
  private get routing() {
    return RoutingStore.get(this);
  }

  /**
   * Экземпляр сервиса Login.
   */
  private get service() {
    return Service.get(this);
  }

  /**
   * Экземпляр сервиса Auth.
   */
  private get auth() {
    return AuthStore.get(this);
  }

  /**
   * Экземпляр сервиса RPC.
   */
  private get rpc() {
    return CompatService.get(this).rpcService;
  }

  /**
   * Сообщение об ошибке, которое должно быть отображено на форме в данный
   * момент.
   */
  @computed
  public get errorMessage(): string | null {
    const { error } = this;

    if (error instanceof InvalidCodeError) {
      return 'Код подтверждения недействителен';
    }

    if (error instanceof InvalidPinError) {
      return 'Неверный ПИН-код';
    }

    if (error instanceof LimitExceededError) {
      return 'Превышен лимит отправки кода подтверждения. Повторите попытку позднее';
    }

    if (RpcError.isType(error, RpcErrorCode.ActionBlocked)) {
      // Сообщение об ошибке подавляется т.к. страница ошибки обслуживается редиректом.
      return '';
    }

    if (error) {
      const { message } = error;
      return `Неизвестная ошибка ${message}`;
    }

    return null;
  }

  /**
   * Функция, задающая параметр pending
   */
  @action
  private setPending = (pending: boolean) => {
    this.pending = pending;
  };

  /**
   * Функция, задающая параметр pending
   */
  @action
  private setError = (
    err: InvalidCodeError | InvalidPinError | LimitExceededError | undefined,
  ) => {
    this.error = err;
  };

  /**
   * Функция, задающая параметр phone
   */
  @action
  private setPhone = (phone?: PhoneModel) => {
    this.phone = phone;
    this.service.savePhone(this.phone);
  };

  /**
   * Функция задающая параметр пин-кода
   */
  @action
  private setPin = (pin?: string) => {
    this.pin = pin;
    this.service.savePin(this.pin);
  };

  /**
   * Осуществляет вызов асинхронной операции.
   */
  private process = async (worker: () => Promise<any>) => {
    try {
      this.setPending(true);

      await worker();

      this.setError(undefined);
    } catch (error) {
      this.setError(error as any);
    } finally {
      this.setPending(false);
    }
  };

  /**
   * Авторизует пользователя с указанными токенами авторизации.
   * @param tokens Токены авторизации.
   */
  @action
  private authorize = (tokens: TokensModel) => {
    if (this.phone == null) {
      throw new Error(`this.phone is undefined`);
    }

    const backUrl = this.auth.backUrl as string;

    runInAction(() => {
      this.setPhone(undefined);
      this.setPin(undefined);

      this.auth.setTokens(tokens);
      this.auth.setBackUrl(undefined);
    });

    return this.routing.backTo(backUrl);
  };

  /**
   * Функция задающая параметр countdown
   */
  @action
  private setCountdown = (value: number) => {
    this.countdown = value;
  };

  /**
   * Функция отсчитывающая тайм-аут, до отправки нового кода подтверждения.
   */
  @action
  private countdownTick = () => {
    this.setCountdown(this.countdown - 1);

    if (this.countdown === 0) {
      clearInterval(this.interval);
    }
  };

  /**
   * @inheritdoc
   */
  fetch() {
    const phone = this.service.readPhone();
    const pin = this.service.readPin();

    return () => {
      this.phone = phone;
      this.pin = pin;
    };
  }

  /**
   * Вызывает переход на предыдущий шаг авторизации.
   */
  public back = () => {
    this.routing.back();
  };

  /**
   * Принимает указанный телефонный номер; определяет его тип и решает, что делать с его обладателем.
   * @param phoneValue Номер телефона.
   */
  @action
  public submitPhone = async (phoneValue: string): Promise<void> => {
    this.process(async () => {
      const type = await this.rpc.getUserState(phoneValue);

      const phone: PhoneModel = { type, value: phoneValue };
      this.setPhone(phone);

      if (type === UserState.Unknown) {
        const tokens = await this.rpc.registerUserByPhone(phoneValue);
        this.authorize(tokens);

        return;
      }

      if (type === UserState.WithoutPassword) {
        await this.routing.push(Page.LOGIN_NEW_PIN);
        return;
      }

      if (type === UserState.Active) {
        await this.routing.push(Page.LOGIN_PIN);
      }
    });
  };

  /**
   * Авторизует пользователя по существующему пин-коду.
   * @param pin Номер пин-кода.
   */
  @action
  public submitPin = async (pin: string): Promise<void> => {
    this.process(async () => {
      if (this.phone == null) {
        throw new Error(`this.phone is undefined`);
      }

      try {
        const tokens = await this.rpc.login(this.phone.value, pin);
        this.authorize(tokens);
      } catch (e) {
        if (RpcError.isType(e, RpcErrorCode.ActionBlocked)) {
          await this.routing.push(Page.ACCOUNT_UNAVAILABLE);
        }

        throw new InvalidPinError(e as any);
      }
    });
  };

  /**
   * Пользователь создает пин-код.
   * @param pin Номер пин-кода.
   */
  @action
  public createPin = (pin: string): void => {
    this.setPin(pin);

    this.requestCode();
    this.routing.push(Page.LOGIN_PIN_CONFIRMATION);
  };

  /**
   * Отправляет запрос кода подтверждения на номер телефона пользователя.
   */
  @action
  public requestCode = async () => {
    this.process(async () => {
      if (this.phone == null) {
        throw new Error(`this.phone is undefined`);
      }

      if (this.countdown > 0) {
        return;
      }

      try {
        await this.rpc.sendConfirmationCode(this.phone.value);
      } catch (e) {
        throw new LimitExceededError(e as any);
      }

      this.setCountdown(60);
      this.interval = setInterval(this.countdownTick, 1000);
    });
  };

  /**
   * Создаёт (меняет) пин-код пользователя и авторизует его.
   */
  @action
  public submitCode = async (code: string): Promise<void> => {
    this.process(async () => {
      if (this.phone == null) {
        throw new Error(`this.phone is undefined`);
      }

      if (this.pin == null) {
        throw new Error(`this.pin is undefined`);
      }

      let tokens: AuthTokens;

      try {
        tokens = await this.rpc.setUserPassword(
          this.phone.value,
          this.pin,
          code,
        );
      } catch (e) {
        throw new InvalidCodeError(e as any);
      }

      this.authorize(tokens);
    });
  };

  /**
   * Освобождает все занятые экземпляром сервиса ресурсы, подготавливая его к
   * удалению.
   */
  @action
  public dispose() {
    this.phone = undefined;
    this.pin = undefined;
    this.error = undefined;
    this.pending = false;
    this.countdown = 0;
    this.interval = undefined;
    this.setPin(undefined);
    this.setPhone(undefined);
  }

  /**
   * @inheritdoc
   */
  clear() {
    this.phone = undefined;
    this.pin = undefined;
    this.error = undefined;
    this.pending = false;
    this.countdown = 0;
    this.interval = undefined;
    this.setPin(undefined);
    this.setPhone(undefined);
  }
}
