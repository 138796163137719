import { ComponentPropsWithoutRef } from 'react';
import styled from '@emotion/styled';
import { Themed, css } from '@emotion/react';

import { Button, LinkButton } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<'div'> & {
  /**
   * Направление группировки действий.
   */
  direction: 'vertical' | 'horizontal';
};

const directionStyle = (props: Themed<Props>) => {
  const { direction } = props;

  switch (direction) {
    case 'vertical':
      return css`
        grid-auto-rows: min-content;
        grid-auto-flow: row;
        gap: 1.5rem;
      `;

    default:
      return css`
        grid-auto-columns: max-content;
        grid-auto-flow: column;
        justify-content: space-around;
      `;
  }
};

/**
 * Корневой элемент блока действий.
 */
export const Root = styled.div<Props>`
  display: grid;

  font-size: 0.81rem;
  line-height: 0.93rem;

  ${Button}, ${LinkButton} {
    padding: 0;
  }

  ${directionStyle}

  margin-top: 1.875rem;
`;
