import { FC, ComponentPropsWithoutRef, useState, useCallback } from 'react';

import { Content } from './Content';
import { Toggle } from './Toggle';

import { Root } from './Notes.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Отображает разворачиваемый блок замечаний "Не приходит код".
 */
export const Notes: FC<Props> = ({ ...props }) => {
  const [active, setActive] = useState<boolean>(false);

  const toggle = useCallback(() => {
    setActive((value) => !value);
  }, []);

  return (
    <Root {...props}>
      <Toggle onClick={toggle} active={active} />
      <Content visible={active} />
    </Root>
  );
};
