import * as yup from 'yup';

import { PinHelper } from 'modules/common/values';

/**
 * Схема формы ПИН-кода.
 */
export const schema = yup.object().shape({
  /**
   * SMS-код.
   */
  code: yup
    .string()
    .required('Введите код из SMS')
    .matches(PinHelper.VALIDATION_REGEXP, 'Неверный код подтверждения'),
});

/**
 * Значения схемы.
 */
export type Values = yup.InferType<typeof schema>;
