import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Actions.Root';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'direction'> & {
  /**
   * Направление группировки действий.
   * @default 'vertical'
   */
  direction?: 'vertical' | 'horizontal';
};

/**
 * Отображает блок действий.
 */
export const Actions: FC<Props> = ({
  children,
  direction = 'vertical',
  ...props
}) => (
  <Root {...props} direction={direction}>
    {children}
  </Root>
);
