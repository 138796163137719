import styled from '@emotion/styled';

import { Form } from '../Form';

/**
 * Корневой элемент формы.
 */
export const Root = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  margin-top: 2rem;
`;
