import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { PageLoading } from 'modules/common/layout';

import { Store } from '../../stores';

/**
 * Инициализирует хранилище состояния процесса авторизации.
 */
const Provider: FC = ({ children }) => {
  const store = Store.use();

  useEffect(() => {
    store.touch();
  });

  return store.ready ? <>{children}</> : <PageLoading />;
};

const component = observer(Provider);
export { component as Provider };
