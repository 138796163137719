import styled from '@emotion/styled';

/**
 * Элемент заголовка блока.
 */
export const Heading = styled.h3`
  margin-bottom: 0;
  margin-top: 0;

  font-weight: bold;
  font-size: inherit;
`;
