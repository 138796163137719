import styled from '@emotion/styled';

/**
 * Обёртка для содержимого компонента.
 */
export const Inner = styled.div`
  background-color: ${(p) => p.theme.color.background.main.main};

  border-color: ${(p) => p.theme.color.info.main};
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;

  padding: 1.25rem;

  font-size: 0.875rem;
  text-align: center;
  color: ${(p) => p.theme.color.text.main.great};
`;
