import { FC, ComponentPropsWithoutRef } from 'react';
import { observer } from 'mobx-react';

import { Button } from 'modules/common/ui';
import { Store } from 'modules/login';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Button>, 'type' | 'disabled'>;

/**
 * Отображает кнопку повторной отправки.
 */
const Resend: FC<Props> = ({ onClick, ...props }) => {
  const { requestCode, countdown, pending } = Store.use();

  const isPending = pending || countdown > 0;

  return (
    <Button {...props} type="button" onClick={requestCode} disabled={isPending}>
      <span>Отправить ещё раз</span>
      {countdown > 0 ? <span> ({countdown} сек.)</span> : null}
    </Button>
  );
};

const component = observer(Resend);
export { component as Resend };
