import * as yup from 'yup';
import { PhoneHelper } from 'modules/common/values/helpers';
/**
 * Схема формы телефона.
 */
export const schema = yup.object().shape({
  /**
   * Номер телефона не может быть пустым.
   */
  phone: yup
    .string()
    .required('Введите номер телефона')
    .matches(PhoneHelper.VALIDATION_REGEXP, 'Введен неверный номер телефона'),
});

/**
 * Значения схемы.
 */
export type Values = yup.InferType<typeof schema>;
