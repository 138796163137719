import { FC, ComponentPropsWithoutRef, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { getIn } from 'formik';

import { Field, LinkButton, Button } from 'modules/common/ui';
import { Page } from 'modules/common/routing';

import { Form } from '../Form';
import { Actions } from '../Actions';

import { Input } from './Input';
import { Store } from '../../stores';

import { schema } from './PinForm.Schema';

const PIN = 'pin';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Form>;

/**
 * Страница создания ПИН-кода.
 */
const PinForm: FC<Props> = ({ ...props }) => {
  const store = Store.use();

  const handleSubmit = useCallback(
    (values: any) => {
      const value = getIn(values, PIN);
      store.submitPin(value);
    },
    [store],
  );

  const handleCancel = useCallback(() => {
    store.back();
  }, [store]);

  useEffect(() => {
    const input = window.document.getElementById(PIN);

    if (store.pending === false && input != null) {
      input.focus();
    }
  }, [store.pending]);

  return (
    <Form {...props} schema={schema} onSubmit={handleSubmit}>
      <Field
        label="Введите пин-код"
        align="center"
        htmlFor={PIN}
        name={PIN}
        error={store.errorMessage}
      >
        <Input
          id={PIN}
          name={PIN}
          secure
          disabled={store.pending}
          autoFocus
          autoComplete="off"
        />
      </Field>
      <Actions direction="horizontal">
        <Button onClick={handleCancel} size="small" color="info">
          Отмена
        </Button>
        <LinkButton href={Page.LOGIN_PIN_RECOVERY} size="small" color="info">
          Забыли пин-код?
        </LinkButton>
      </Actions>
    </Form>
  );
};

const component = observer(PinForm);
export { component as PinForm };
