import { FC, ComponentPropsWithoutRef, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { getIn } from 'formik';

import { Field, Button } from 'modules/common/ui';
import { Store } from 'modules/login';

import { Actions } from '../Actions';

import { Resend } from './Resend';
import { Input } from './Input';
import { Notes } from './Notes';
import { Root } from './PinConfirmationForm.Root';

import { schema } from './PinConfirmationForm.Schema';

const CODE = 'code';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'schema'>;

/**
 * Отображает форму подтверждения ПИН-кода.
 */
const PinConfirmationForm: FC<Props> = ({ ...props }) => {
  const store = Store.use();

  const handleSubmit = useCallback(
    (values: any) => {
      const code = getIn(values, CODE);
      store.submitCode(code);
    },
    [store],
  );

  const handleCancel = useCallback(() => {
    store.back();
  }, [store]);

  useEffect(() => {
    const input = window.document.getElementById(CODE);

    if (store.pending === false && input != null) {
      input.focus();
    }
  }, [store.pending]);

  return (
    <Root {...props} schema={schema} onSubmit={handleSubmit}>
      <Field
        label="СМС-код"
        align="center"
        htmlFor={CODE}
        name={CODE}
        nativeErrors={false}
        error={store.errorMessage}
      >
        <Input id={CODE} name={CODE} disabled={store.pending} autoFocus />
      </Field>

      <Actions>
        <Resend width="auto" color="info">
          Отправить ещё раз
        </Resend>
        <Notes />
        <Button onClick={handleCancel} color="info">
          Отмена
        </Button>
      </Actions>
    </Root>
  );
};

const component = observer(PinConfirmationForm);
export { component as PinConfirmationForm };
