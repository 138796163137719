import { CustomError } from '@devim-front/error';

/**
 * Представляет ошибку, когда используется неверный код подтверждения
 * для создания или смены пин-кода.
 */
export class InvalidCodeError extends CustomError {
  public constructor(innerError: Error) {
    super('InvalidCodeError', innerError.message);
  }
}
