import { FC } from 'react';

import { PageLoading } from 'modules/common/layout';
import { Page, Redirect } from 'modules/common/routing';
import { Store } from 'modules/common/auth';

/**
 * Данный компонент, по сути, является большим костылём.
 *
 * На данный момент авторизация работает так: пользователь пытается зайти на
 * приватную страницу. Затем его перебрасывает в раздел авторизации. Он входит.
 * После этого пользователя откатывает по браузерной истории таким образом,
 * будто он и вовсе не заходил в раздел авторизации, а сразу же перешёл на
 * первоначальную страницу.
 *
 * Этот компонент исправляет ситуацию, когда неавторизованный пользователь
 * сразу же переходит в раздел авторизаризации. Без него при откате истории
 * пользователя буквально выкинет с сайта. Чтобы этого избежать, компонент
 * совершает перенаправление на главную страницу ЛК, чтобы оттуда его уже
 * перебросило обратно сюда. Тогда в браузерной истории появится необходимая
 * "останавливающая" запись.
 */
export const Access: FC = ({ children }) => {
  const store = Store.use();

  if (store.backUrl) {
    return <>{children}</>;
  }

  return (
    <Redirect url={Page.ACCOUNT} replace>
      <PageLoading />
    </Redirect>
  );
};
