import { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { Field, TextInput, Button, InputGroup } from 'modules/common/ui';
import { PhoneHelper } from 'modules/common/values/helpers';
import { Store } from 'modules/login';

import { Form } from '../Form';

import { schema } from './PhoneForm.Schema';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Form>;

/**
 * Отображает форму ввода номера телефона.
 */
const PhoneForm: FC<Props> = ({ ...props }) => {
  const store = Store.use();

  const logIn = useCallback(
    (data: any) => {
      const phoneParse = PhoneHelper.parse(data.phone);
      store.submitPhone(phoneParse);
    },
    [store],
  );

  return (
    <Form {...props} onSubmit={logIn} schema={schema}>
      <InputGroup width="medium" label="Номер телефона">
        <Field htmlFor="phone" name="phone">
          <TextInput
            hovered={false}
            placeholder="+7 (900) 000-00-00"
            id="phone"
            name="phone"
            type="tel"
            autoComplete="off"
            inputMode="numeric"
            mask="+7 (000) 000-00-00"
            autoFocus
          />
        </Field>

        <div>
          <Button
            width="full"
            variant="filled"
            type="submit"
            pending={store.pending}
          >
            Войти
          </Button>
        </div>
      </InputGroup>
    </Form>
  );
};

const component = observer(PhoneForm);
export { component as PhoneForm };
